import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css'
import './index.css';
import 'overlayscrollbars/css/OverlayScrollbars.css'
import App from './AppZh'

import { supportsWebp } from './utils'

supportsWebp(window).then(res => {
    if (res) {
        window.document.body.className = 'webp'
    }
}).catch(err => {
    console.log(err)
})

console.log("run index zh ==========")

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
