import React from 'react';
import StyledThemeProvider from './components/StyledThemeProvider'
// import { Router } from "@reach/router"
import DrumNetZh from "./features/welcome/DrumNetZh";
// import {observer} from "mobx-react-lite";


// const Routes = () => observer(<Router>
//     <DrumNetZh path="*" />
// </Router>)

const AppZh = () => {
    console.log('run app zh')
    return (
        <StyledThemeProvider scope="app">
            <DrumNetZh />
        </StyledThemeProvider>
    )
};

export default AppZh;
