import React, {useState, useEffect} from 'react'
import {Button} from '@mui/material'
import useTitle from '../../hooks/title'
import classes from './DrumNetZh.module.scss'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {ReactComponent as Windows} from '../../images/windows.svg'
import {ReactComponent as Mac} from '../../images/mac.svg'
import {ReactComponent as Msg} from '../../images/msg.svg'
import useWindowSize from '../../hooks/window-size';
import clsx from 'clsx'
import layoutStyles from '../layout/Layout.module.scss'
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import LoadingButton from '@mui/lab/LoadingButton';
import {observer, useLocalObservable} from "mobx-react-lite";
import {createApi} from "../common/API";
import Dialog from "@mui/material/Dialog";
import QR from '../../images/contact-qr.jpg';
import CancelIcon from '@mui/icons-material/Cancel';

// https://sessionloops.com/api/app/createOrderWithCode?appId=DrumNet&code=0A2663FB50
const userRegister = createApi('/user/register', {method: 'POST', expandData: true, notify: true, noThrow: false});
const createOrderWithCode = createApi('/app/createOrderWithCode', {method: 'POST', expandData: true, notify: true, noThrow: false})
const showApp = createApi('/app/getApps', {method: 'POST', expandData: true, notify: true, noThrow: false});

const DrumNetZh = () => {
    console.log('run DrumNet zh')
    const [version, setVersion] = useState('');
    useTitle("DrumNet - 无限生成采样的AI鼓机")
    const windowSize = useWindowSize();
    const state = useLocalObservable(() => ({
        showQR: false,
        openQR() {
            this.showQR = true
        },
        closeQR() {
            this.showQR = false
        }
    }))
    
    const form = useLocalObservable(() => ({
        email: '',
        password: '',
        code: '',
        posting: false,
        immediately: false,
        errors: {email: null, password: null, code: null},
        success: false,
        handleEmail(e) {
            this.email = e.target.value.trim()
            this.immediately && this.validEmail()
        },
        handlePassword(e) {
            this.password = e.target.value.trim()
            this.immediately && this.validPassword()
        },
        handleCode(e) {
            this.code = e.target.value.trim()
            this.immediately && this.validCode()
        },
        validEmail() {
            if (this.email.length < 1) {
                this.errors.email = '请输入邮箱'
                return false
            }
            const pattern = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/g
            if (!(pattern.test(this.email.toString()))) {
                this.errors.email = '请输入正确邮箱'
                return false
            }
            this.errors.email = null
            return true
        },
        validPassword() {
            if (this.password.length < 1) {
                this.errors.password = '请输入密码'
                return false
            }
            if (this.password.length < 8) {
                this.errors.password = '密码不能少于8位'
                return false
            }
            this.errors.password = null
            return true
        },
        validCode() {
            if (this.code.length < 1) {
                this.errors.code = '请输入兑换码'
                return false
            }
            this.errors.code = null
            return true;
        },
        handleKeyup(e) {
            if(e.keyCode === 13) {
                this.handleSubmit()
            }
        },
        // block() {
        //     return new Promise((resolve, reject) => {
        //         setTimeout(resolve, 2000)
        //     })
        // },
        async handleSubmit() {

            this.posting = true
            this.immediately = true
            const ck = [this.validEmail(), this.validPassword(), this.validCode()]
            if (ck.some(c => !c)) {
                this.posting = false
                console.log('校验失败....')
                return;
            }

            try {
                // 注册
                // console.log('开始注册....')
                await userRegister({email: this.email, password: this.password})
                // await this.block()
            } catch (e) {
                console.log('reg fail', e)
                this.errors.email = '注册失败'
                this.posting = false
                return;
            }

            try {
                // 兑换
                // console.log('开始兑换....')
                await createOrderWithCode({appId: 'DrumNet', code: this.code})
                // await this.block()
                this.success = true

                this.email = ''
                this.password = ''
                this.code = ''

                // setTimeout(() => {
                //     window.location.reload()
                // }, 3000);
            } catch (e) {
                console.log('code fail', e)
                this.errors.code = '兑换失败'
            }
            this.posting = false
            // console.log('结束')
        },
    }));

    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchApiData = async () => {
            try {
              const response = await showApp();
              setVersion(response[1].version)
            } catch (error) {
              console.error('Error fetching API data:', error);
            }
          };
        fetchApiData()
    }, [])
    return <div className={clsx(classes.root, layoutStyles.root)}>
      {/*{userStore.isAnonymous ? <Header /> : <AuthHeader />}*/}
        <div className={classes.banner}>
            <div className={classes.title}>DrumNet</div>
            {windowSize.width < 500 ?
            <div className={classes.description1}>无限生成采样的AI鼓机</div>:
            <div className={classes.description2}>无限生成采样的AI鼓机</div>
            }
        </div>
        <div className={classes.options} >
            <div className={clsx(classes.option, classes.purple)}  style={windowSize.width < 500 ? {paddingLeft: 'calc(35vw + 2px)'} : {}}
                onClick = {() => document.getElementById("buys").scrollIntoView({block:"center", behavior:"smooth"})}>
                <ShoppingCartIcon/>
                <div className={classes.optionText} style={windowSize.width < 500 ? {marginLeft : 22} : {}}>产品兑换</div>
            </div>
            <div className={clsx(classes.option, classes.purple)} style={windowSize.width < 500 ? {borderTop: '1.5px solid white', borderBottom: '1.5px solid white'} :
            {borderLeft: '1.5px solid white', borderRight: '1.5px solid white'}}
                onClick = {() => document.getElementById("downloads").scrollIntoView({block:"center", behavior:"smooth"})}
            >
                <SaveAltIcon style={{fontSize: 28}}/>
                <div className={classes.optionText}>{windowSize.width < 500 ? '免费下载' : '免费下载'}</div>
            </div>
            <Dialog onClose={() => state.closeQR()} open={state.showQR} classes={{paper:classes.qrPaper}}>
                <CancelIcon className={classes.closeIcon} onClick={() => state.closeQR()} />
                <img src={QR} width={200}  alt="联系我们"/>
            </Dialog>
            <div className={clsx(classes.option, classes.purple)} onClick={() => state.openQR()}>
                <Msg style={{width: 27, height: 27}}/>
                <div className={classes.optionText}>{windowSize.width < 500 ? '联系我们' : '联系我们'}</div>
            </div>
        </div>
        <div className={classes.content}>
            <div className={classes.feature1}>
                <div className={classes.feature1Title}>什么是DrumNet</div>
                <div className={classes.feature1Description}>DrumNet不仅仅是又一款基于采样器的鼓机，利用最先进的AIGC技术，它让您能够轻松地在任何音乐风格中生成自己的鼓采样并随意修改，无需任何专业知识。</div>
            </div>
            <div className={classes.feature4}>
                <div className={classes.feature4Title}>生成已有采样的无限变体</div>
                <div className={classes.feature4Description}>凭借我们创新的深度重采样技术，您现在可以释放无限的可能性，创造独特定制的鼓声变化，为您的音乐赋予独特优势，让您的创造力飞向新的高度。</div>
            </div>
            <div className={classes.feature2}>
                <div className={classes.feature2Title}>简单易用<br />且功能强大的音序器
                </div>
                <div className={classes.feature2Description}>无论您是想要创作复杂的鼓点节奏还是简单的律动，我们直观的界面大大简化了节奏型创建，最大限度地减少了编辑成本。</div>
            </div>
            <div className={classes.feature3}>
                <div className={classes.feature3Title}>2OO条<br/>官方预置</div>
                <div className={classes.feature3Description}>从我们精心打造的2OO条预置开始上手。探索House、Disco、R&B、Hip Hop等多种风格的鼓声，每条都包含独立的分轨。</div>
            </div>
            <div className={classes.downloadTips}>
                <div className={classes.downloadTipsTitle}>DrumNet</div>
                <div className={classes.downloadTipsAction}>免费下载</div>
            </div>
            <div id="downloads" className={classes.downloads}>
                <div className={classes.download}>
                    <Button startIcon={<Windows style={{marginLeft: 7, marginRight: 10}}/>} variant="contained" className={classes.button}  href="https://sessionloops.oss-cn-beijing.aliyuncs.com/DrumNet_Installer.exe" target="_blank">Windows下载</Button>
                    最新版本 {version}，Windows 7 及更高
                </div>
                <div className={classes.download}>
                    <Button startIcon={<Mac style={{marginLeft: 7, marginRight: 10}}/>} variant="contained" className={classes.button}  href="https://sessionloops.oss-cn-beijing.aliyuncs.com/DrumNet_Installer.pkg" target="_blank">Mac下载</Button>
                    最新版本 {version}，macOS 10.14 及更高，全芯片支持
                </div>
            </div>
            <div className={classes.text}>您可以尝试除导入导出之外的所有功能。<br />
                要访问完整版本，请在下方兑换授权。</div>
            <div id="buys" className={classes.exchange}>
                <FormControl className={clsx(classes.field)}>
                    <OutlinedInput
                        name="email"
                        size="small"
                        placeholder="邮箱"
                        value={form.email}
                        onChange={form.handleEmail}
                        onKeyUp={form.handleKeyup} />
                </FormControl>
                <FormControl className={clsx(classes.field)}>
                    <OutlinedInput
                        name="password"
                        size="small"
                        type="password"
                        placeholder="密码：不少于8位"
                        value={form.password}
                        onChange={form.handlePassword}
                        onKeyUp={form.handleKeyup} />
                </FormControl>
                <FormControl className={clsx(classes.field)}>
                    <OutlinedInput
                        name="code"
                        size="small"
                        placeholder="兑换码"
                        value={form.code}
                        onChange={form.handleCode}
                        onKeyUp={form.handleKeyup} />
                </FormControl>
                <div className={clsx(classes.tipsMsg, form.success ? classes.tipsMsgSucc : classes.tipsMsgErr)}>
                    {(form.success && '兑换成功!请在App内激活！') || form.errors.email || form.errors.password || form.errors.code || ''}
                </div>
                <LoadingButton
                    className={classes.submit}
                    variant="contained"
                    disableElevation
                    loading={form.posting}
                    onClick={form.handleSubmit}
                >兑换</LoadingButton>
            </div>
        </div>
        <div className={classes.copyright}></div>
      </div>
  }

  export default observer(DrumNetZh)
